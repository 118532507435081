interface Config {
  SENTRY_ENVIRONMENT: string
  DEBUG: boolean
  LOG_LEVEL: string
  SENTRY_DSN: string | undefined
  SENTRY_ENABLED: boolean
  MAILCHIMP_ENABLED: boolean
}

interface Environments {
  development: Config
  staging: Config
  production: Config
}

const SENTRY_DSN =
  'https://84d3fdc7712086d022da562c6d014ccd@o4507141337645056.ingest.de.sentry.io/4507148436832336'

const Environments: Environments = {
  development: {
    SENTRY_ENVIRONMENT: 'development',
    DEBUG: true,
    LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL || 'debug',
    SENTRY_DSN,
    SENTRY_ENABLED: false,
    MAILCHIMP_ENABLED: true,
  },
  staging: {
    SENTRY_ENVIRONMENT: 'staging',
    DEBUG: false,
    LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL || 'debug',
    SENTRY_DSN,
    SENTRY_ENABLED: false,
    MAILCHIMP_ENABLED: true,
  },
  production: {
    SENTRY_ENVIRONMENT: 'production',
    DEBUG: false,
    LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL || 'info',
    SENTRY_DSN,
    SENTRY_ENABLED: true,
    MAILCHIMP_ENABLED: true,
  },
}

const isProductionEnv = process.env.NEXT_PUBLIC_APP_ENV === 'production'
const isStagingEnv = process.env.NEXT_PUBLIC_APP_ENV === 'staging'
const isDevelopmentEnv = !isStagingEnv && !isProductionEnv

const getConfig = (): Config => {
  if (isProductionEnv) {
    return Environments.production
  }
  if (isStagingEnv) {
    return Environments.staging
  }
  return Environments.development
}

const config = getConfig()

export { config, isProductionEnv, isStagingEnv, isDevelopmentEnv }
